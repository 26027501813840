import React, { Suspense } from "react";
import {RouterProvider} from "react-router-dom";
import {ConfigProvider} from "antd";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import {persistor, store} from '@arboxappv4/shared/src/redux/store'
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import router from "./Routing";
import './index.scss'
import * as Sentry from "@sentry/react";
import SuspenseLoader from "./src/components/SuspenseLoader";
import {useTranslation} from "react-i18next";

Sentry.init({
    dsn: "https://907b48ed75214325b814f1130d30f4fb@o458189.ingest.sentry.io/5455287",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay()
    ],
    // debug: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const App = () => {
    const {i18n} = useTranslation()

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ConfigProvider
                    direction={i18n.dir()}
                    theme={{
                    token: {
                        colorPrimary: Colors.siteBlack,
                        borderRadius: 5,
                        colorError: Colors.redPinkColor,
                        fontFamily: 'Ploni',
                        fontSize: 16,
                        colorText: Colors.siteBlack,
                        colorSuccessBg: Colors.siteSuccessBg,
                        colorSuccessBorder: Colors.siteSuccessBorder,
                    },
                    components: {
                        Input: {
                            colorBgContainer: Colors.white,
                            colorBorder: Colors.siteScheduleBorder,
                            controlOutlineWidth: 0,
                            colorPrimaryHover: Colors.lightGrey,
                            fontSize: 16,
                            controlHeight: 38,
                            activeBg: Colors.white,
                            activeBorderColor: Colors.siteBlack,
                            hoverBg: Colors.inputHoverBg,
                            hoverBorderColor: Colors.siteScheduleBorder,
                        },
                        Button: {
                            controlHeight: 38,
                            controlHeightLG: 49,
                            lineWidth: 1,
                            colorBorder: Colors.siteBlack,
                            colorPrimaryHover: Colors.siteBlackHover,
                            fontSizeLG: 20,
                        },
                        DatePicker: {
                            controlOutlineWidth: 0,
                            fontFamily: 'Ploni',
                            controlHeight: 38,
                            colorBgContainer: Colors.white,
                            activeBg: Colors.white,
                            hoverBg: Colors.inputHoverBg,
                            activeBorderColor: Colors.siteBlack,
                            hoverBorderColor: Colors.siteScheduleBorder,
                            colorBorder: Colors.siteScheduleBorder,
                        },
                        Select: {
                            controlItemBgActive: 'transparent',
                            controlHeight: 38,
                            colorBgContainer: Colors.white,
                            colorBorder: Colors.siteScheduleBorder,
                            colorPrimaryHover: Colors.greyText,
                            controlOutline: 'rgba(33,33,33,0.1)'
                        },
                        Collapse: {
                            colorTextDisabled: 'rgba(0,0,0,0.5)'
                        },
                        Avatar: {
                            textFontSize: 16,
                            colorTextLightSolid: Colors.siteBlack
                        },
                        Card: {
                            boxShadowCard: '-2px 4px 8px 0px #F1F1F1',
                        },
                        Divider: {
                            colorText: Colors.siteBlack,
                            fontSize: 14
                        }
                    }
                }}>
                    <Suspense fallback={<SuspenseLoader/>}>
                        <RouterProvider router={router} />
                    </Suspense>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;

